<template>
	<div class="departmentManager">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline search-form">
				<el-form-item label="设备名称：">
					<el-autocomplete
						v-model.trim="formInline.smartDeviceName"
						:fetch-suggestions="querySearchAsync"
						clearable
						placeholder="请输入内容"
					></el-autocomplete>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:is-show-selection="false"
				:showOperation="false"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
			</Table>
		</div>
	</div>
</template>

<script>
export default {
	name: 'useHistory',
	components: {
		Table: () => import('@/components/Table/table'),
	},
	data() {
		return {
			loading: false,
			formInline: {
				smartDeviceName: '',
			},
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '设备编号',
					prop: 'smartDeviceModelSerialNumber',
					formatter: (row) => {
						return row.smartDeviceModelSerialNumber || '-';
					},
				},
				{
					label: '设备名称',
					prop: 'smartDeviceName',
					formatter: (row) => {
						return row.smartDeviceName || '-';
					},
				},
				{
					label: '使用账号',
					prop: 'usedAccount',
					formatter: (row) => {
						return row.usedAccount || '-';
					},
				},
				{
					label: '缴费形式',
					prop: 'paymentMethod',
					formatter: (row) => {
						return row.paymentMethod || '-';
					},
				},
				{
					label: '收取费用(元)',
					prop: 'cost',
					formatter: (row) => {
						return row.cost ? (row.cost / 100).toFixed(2) : '-';
					},
				},
				{
					label: '开始时间',
					prop: 'startTime',
					formatter: (row) => {
						return row.startTime || '-';
					},
				},
				{
					label: '结束时间',
					prop: 'endTime',
					formatter: (row) => {
						return row.endTime || '-';
					},
				},
			],
			options: [],
			filterLoading: false,
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getList();
	},
	methods: {
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		onSearch() {
			this.queryData.current = 1;
			this.getList();
		},

		//获取列表
		getList(type = '') {
			type == 'filter' ? (this.filterLoading = true) : (this.loading = true);
			let data = {
				...this.formInline,
				...this.queryData,
			};
			this.$http
				.get(this.api['SmartDeviceRecord#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						if (type == 'filter') {
							this.filterLoading = false;
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: item.smartDeviceName,
								};
							});
						} else {
							this.tableData = res.data.collection || [];
							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .search-form {
	.el-select {
		width: 120px;
		.el-input {
			width: 120px;
			.el-input__inner {
				width: 120px;
			}
		}
	}
	.el-input {
		width: 160px;
	}
}
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
</style>